import {ACTIONS, SERVICES, FINAL_TYPES, SCHEMAS} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'В Центр-сити все любят кофе. Но оказалось, что помещение требует ремонта. Что делать?',
        answers: [
            {
                text: 'У меня нет денег на капитальный ремонт. Потрачу последние 5 000 Ц на обустройство.',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 1,
            },
            {
                text: 'Кредит! А разве могут быть другие варианты.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                schema: SCHEMAS.CREDIT_CAFE_SCHEMA,
                nextStepIndex: 0,
            },
            {
                text: 'Можно занять денег у моего нового друга.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 1,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Трубы оказались слишком старыми и кофейню затопило. Видимо, не нужно было откладывать ремонт.',
        answers: [
            {
                text: 'Возьму кредит.',
                action: ACTIONS.NEXT,
                stepFund: 30000,
                increase: true,
                nextStepIndex: 0,
                schema: SCHEMAS.CREDIT_CAFE_SCHEMA,
            },
            {
                text: 'Мой знакомый уже предлагал занять деньги. Пожалуй, пора воспользоваться предложением.',
                action: ACTIONS.NEXT,
                stepFund: 30000,
                increase: true,
                nextStepIndex: 2,
            },
            {
                text: 'Продам бизнес. У меня нет денег на восстановление.',
                action: ACTIONS.ONE_MORE,
                stepFund: 30000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Кофейня продана. Вы очень расстроились, позже узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 2,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Вашему новому знакомому срочно понадобились деньги, и он требует вернуть долг. Но вы всё потратили.',
        fund: 0,
        answers: [
            {
                text: 'Может еще не поздно взять кредит?',
                action: ACTIONS.NEXT,
                stepFund: 100000,
                increase: true,
                nextStepIndex: 3,
            },
            {
                text: 'Просто не буду возвращать. У меня нет денег.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: '',
                    message: 'Ваш знакомый поставщик оказался хорошим юристом и не забыл оформить договор займа. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'У меня нет выхода. Отдам ему свой бизнес.',
                action: ACTIONS.ONE_MORE,
                stepFund: 70000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: '',
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 3,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Это было отличным решением. Вам удалось вернуть деньги и избежать конфликта. Но нужно развивать бизнес, а знаний не достаточно.',
        fund: 30000,
        answers: [
            {
                text: 'В Интернете очень много информации. Обучусь самостоятельно, куплю подписку на бизнес-канал.',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 4,
            },
            {
                text: 'Куплю курс про развитие бизнеса в Instagram',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 4,
            },
            {
                text: 'Бесплатный акселератор для бизнеса от "Центр-инвест". Интересно, но разве бесплатные знания могут быть полезны?',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: false,
                schema: SCHEMAS.ACC_CAFE_SCHEMA,
                nextStepIndex: 0,
            },
        ],
    },
    {
        stepIndex: 4,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Информации оказалось слишком много. Вы потеряли не только драгоценное время, но и прибыль, потому что не уделяли должного внимания бизнесу. Нужно спасать ситуацию.',
        answers: [
            {
                text: 'Нужно придумать креативное решение, чтобы спасти бизнес.',
                action: ACTIONS.RANDOM,
                stepFund: 0,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.FAIL_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы проиграли. К сожалению, потеряно много драгоценного времени. Кофейня стала убыточной и вам пришлось продать бизнес. Не расстривайтесь и попробуйте еще раз!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Отлично! Вы сделали вашу кофейню тематической и посетителям это понравилось. У вас получилось занять лидирующие позиции в Центр-сити.',
                    },
                ],
            },
            {
                text: 'Нужно проанализировать конкурентов и разработать бизнес-план.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы очень старались, но, увы, приобретенные знания не помогли спасти бизнес. Видимо платно – не значит «хорошо». Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Мне поможет личный ассистент. Надеюсь, дела пойдут в гору.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 5,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Вашему новому знакомому срочно понадобились деньги, и он требует вернуть долг. Но вы всё потратили.',
        answers: [
            {
                text: 'У меня нет выхода. Отдам ему свой бизнес.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Ваш знакомый поставщик оказался хорошим юристом и не забыл оформить договор займа. Бизнес пришлось продать, чтобы оплатить долги. Но вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Просто не буду возвращать. У меня нет денег.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Ваш знакомый поставщик оказался хорошим юристом и не забыл оформить договор займа. Бизнес пришлось продать, чтобы оплатить долги. Но вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Может еще не поздно взять кредит и отдать долг?',
                action: ACTIONS.NEXT,
                stepFund: 100000,
                increase: true,
                schema: SCHEMAS.CREDIT_40_SCHEMA,
                nextStepIndex: 0,
            },
        ],
    },
];
