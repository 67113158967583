import {ACTIONS, SCHEMAS, FINAL_TYPES, SERVICES} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Отлично, вы зарегистрировались как самозанятый. Но почти все деньги потрачены на приобретение магазина, а вот на материалы не хватило. Что делать?',
        answers: [
            {
                text: 'Займу деньги у моего нового знакомого-поставщика.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                nextStepIndex: 5,
                schema: SCHEMAS.CAFE_SCHEMA,
            },
            {
                text: 'Обращусь в банк и возьму кредит на развитие бизнеса.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                schema: SCHEMAS.CREDIT_55_SCHEMA,
                nextStepIndex: 0,
            },
            {
                text: 'Не буду брать кредит и занимать деньги. Открою магазин, закуплю материалы на 5000 Ц, а расширяться буду с прибыли.',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 1,
            },
        ],
    },
    {
        stepIndex: 1,
        title: '',
        fund: -20000,
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Без ассортимента дела идут очень плохо. Появились долги. Нужно принимать решение.',
        answers: [
            {
                text: 'Мне нужны дополнительные знания, чтобы понять, как управлять бизнесом.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 3,
            },
            {
                text: 'Пора обратиться в банк за помощью.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                schema: SCHEMAS.CREDIT_40_SCHEMA,
                nextStepIndex: 0,
            },
            {
                text: 'Займу деньги у моего нового знакомого-поставщика.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                nextStepIndex: 2,
            },
        ],
    },
    {
        stepIndex: 1.2,
        title: '',
        fund: -20000,
        image: STEP_IMAGES[IMAGES_NAMES.PYRAMID],
        question: 'Предложение арендодателя – это финансовая пирамида. Разве можно строить бизнес с таким партнером? Арендодатель не только хотел обмануть, но и украл ваши последние сбережения. Увы, вы остались с долгами. Нужно принимать решение.',
        answers: [
            {
                text: 'Мне нужны дополнительные знания, чтобы понять, как управлять бизнесом.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 3,
            },
            {
                text: 'Пора обратиться в банк за помощью.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                schema: SCHEMAS.CREDIT_40_SCHEMA,
                nextStepIndex: 0,
            },
            {
                text: 'Займу деньги у моего нового знакомого-поставщика.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                nextStepIndex: 2,
            },
        ],
    },
    {
        stepIndex: 2,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Вашему новому знакомому срочно понадобились деньги, и он требует вернуть долг. Но вы уже всё потратили.',
        fund: 0,
        answers: [
            {
                text: 'У меня нет выхода. Отдам ему свой бизнес.',
                action: ACTIONS.ONE_MORE,
                stepFund: 70000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Просто не буду возвращать. У меня нет денег.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Ваш знакомый поставщик оказался хорошим юристом и не забыл оформить договор займа. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Может ещё не поздно взять кредит?',
                action: ACTIONS.NEXT,
                stepFund: 100000,
                increase: true,
                schema: SCHEMAS.CREDIT_40_SCHEMA,
                nextStepIndex: 1.2,
            },
        ],
    },
    {
        stepIndex: 3,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Теперь мне нужно определиться, как получить знания. Времени мало, нужно спасать бизнес.',
        answers: [
            {
                text: 'Куплю курс про развитие бизнеса. Займу деньги и оплачу обучение.',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 4,
            },
            {
                text: 'Бесплатный акселератор для бизнеса от "Центр-инвест". Разве бесплатные знания могут быть полезны?',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 6,
            },
            {
                text: 'В Интернете очень много информации. Обучусь самостоятельно.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 12,
            },
        ],
    },
    {
        stepIndex: 4,
        title: '',
        fund: -25000,
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Увы, курс оказался совершенно бесполезным. Вы потеряли не только драгоценное время, но и прибыль, потому что не уделяли должного внимания бизнесу. Нужно спасать ситуацию.',
        answers: [
            {
                text: 'Мне поможет личный ассистент. Надеюсь, дела пойдут в гору, и я смогу заплатить ему за спасение.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Нужно проанализировать конкурентов и самостоятельно разработать бизнес-план.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Без капитала я не смогу спасти бизнес. Нужно еще занять денег.',
                action: ACTIONS.NEXT,
                stepFund: 50000,
                increase: true,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 5,
        title: '',
        fund: -75000,
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Вашему новому знакомому срочно понадобились деньги, и он требует вернуть долг. Но вы всё потратили.',
        answers: [
            {
                text: 'Продам бизнес и верну деньги.',
                action: ACTIONS.ONE_MORE,
                stepFund: 75000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'У меня нет выхода. Отдам ему свой бизнес.',
                action: ACTIONS.ONE_MORE,
                stepFund: 75000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Просто не буду возвращать. Скажу, что у меня нет денег.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 6,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Акселератор от "Центр-инвест" помог вам разобраться в продажах и аналитике. Вы решили взять кредит в банке "Центр-инвест". Что дальше?',
        fund: 50000,
        answers: [
            {
                text: 'Нужно расширить ассортимент!',
                action: ACTIONS.NEXT,
                stepFund: 20000,
                increase: false,
                nextStepIndex: 7,
            },
            {
                text: 'Теперь я умею анализировать рынок. Буду производить новые эко-сладости!',
                action: ACTIONS.NEXT,
                stepFund: 20000,
                increase: false,
                nextStepIndex: 11,
            },
            {
                text: 'Найму маркетолога, который сделает рекламу.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 10,
            },
        ],
    },
    {
        stepIndex: 7,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Вы расширили ассортимент и смогли закрыть кредит! А еще разработали 3 варианта развития бизнеса. Что дальше?',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 8,
            },
            {
                text: 'Можно открыть интернет-магазин с эко-сладостями! ',
                action: ACTIONS.RANDOM,
                stepFund: 25000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 10,
            },
        ],
    },
    {
        stepIndex: 8,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Идея оказалась действительно хорошей и ваши продажи выросли! Что дальше?',
        fund: 50000,
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 15000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Пора переходить на Интернет-торговлю. Мне нужен сайт.',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 9,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Ваши конкуренты придумали новое бизнес-решение и обошли вас. Вы теряете прибыль. Нужно действовать - вернёмся назад!',
        fund: 30000,
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 15000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Можно открыть интернет-магазин с эко-сладостями! ',
                action: ACTIONS.NEXT,
                stepFund: 25000,
                increase: false,
                nextStepIndex: 9,
            },
            {
                text: 'Пора переходить на Интернет-торговлю. Мне нужен сайт.',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 10,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы наняли маркетолога, но его рекламная кампания не удалась. Нужно другое решение.',
        answers: [
            {
                text: 'Решено: интернет-торговля.',
                action: ACTIONS.RANDOM,
                stepFund: 25000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Идея отличная, но конкуренты подставили вас. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму более опытного маркетолога!',
                action: ACTIONS.WIN,
                stepFund: 15000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Победа! Благодаря успешной рекламной кампании вам удалось занять лидирующую позицию на рынке и увеличить капитал. Настойчивость привела вас к успеху!',
                },
            },
        ],
    },
    {
        stepIndex: 11,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Новый эко-товар оказался востребованным и вы заняли лидирующие позиции на рынке.',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 8,
            },
            {
                text: 'Можно открыть интернет-магазин с эко-сладостями! ',
                action: ACTIONS.RANDOM,
                stepFund: 25000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 10,
            },
        ],
    },
    {
        stepIndex: 12,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Информации оказалось слишком много. Вы потеряли не только драгоценное время, но и прибыль, потому что не уделяли должного внимания бизнесу. Нужно спасать ситуацию.',
        answers: [
            {
                text: 'Мне поможет личный ассистент. Надеюсь, дела пойдут в гору, и я смогу заплатить ему.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Нужно проанализировать конкурентов и разработать бизнес-план.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы очень старались, но вам не хватило знаний. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Без капитала я не смогу спасти бизнес. Нужно занять денег.',
                action: ACTIONS.NEXT,
                stepFund: 40000,
                increase: true,
                nextStepIndex: 13,
            },
        ],
    },
    {
        stepIndex: 13,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Вашему новому знакомому срочно понадобились деньги, и он требует вернуть долг. Но вы всё потратили!',
        fund: -60000,
        answers: [
            {
                text: 'Продам бизнес и верну деньги поставщику.',
                action: ACTIONS.ONE_MORE,
                stepFund: 100000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'У меня нет выхода. Отдам ему свой бизнес.',
                action: ACTIONS.ONE_MORE,
                stepFund: 100000,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Просто не буду возвращать. Скажу, что у меня нет денег.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Ваш знакомый поставщик оказался хорошим юристом и не забыл оформить договор займа. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
];
