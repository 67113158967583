import boyImage from '@/assets/images/boy.png';
import girlImage from '@/assets/images/girl.png';
import PERSONS_IDS from '@/core/constants/persons';

export const personGender = [
    {
        id: PERSONS_IDS.BOY,
        image: boyImage,
    },
    {
        id: PERSONS_IDS.GIRL,
        image: girlImage,
    },
];


export const personTypes = {
    [PERSONS_IDS.BOY]: [
        {
            name: 'Дима',
            advantages: [
                'Учится в университете',
                'Хорошо разбирается в экономической безопасности и управлении рисками',
                'Амбициозный и находчивый',
            ],
            limitations: [
                'Излишне импульсивен',
            ],
        },
        {
            name: 'Егор',
            advantages: [
                'Работает в кофейне',
                'Мечтает о собственном бизнесе',
                'Активный и общительный',
            ],
            limitations: [
                'Иногда непостоянен в своих решениях',
            ],
        },
        {
            name: 'Алекс',
            advantages: [
                'Программист',
                'Хочет изучать экономику и научиться управлять деньгами',
                'Рассудительный и целеустремленный',
            ],
            limitations: [
                'Тяжело заводит новые знакомства',
            ],
        },
        {
            name: 'Матвей',
            advantages: [
                'Закончил ВУЗ',
                'Мечтает о собственном доме и успешной карьере',
                'Внимательный и трудолюбивый',
            ],
            limitations: [
                'Медлителен в принятии решений',
            ],
        },
    ],
    [PERSONS_IDS.GIRL]: [
        {
            name: 'Кристина',
            advantages: [
                'Учится в университете',
                'Хорошо разбирается в экономической безопасности и управлении рисками',
                'Амбициозная и находчивая',
            ],
            limitations: [
                'Излишне импульсивна',
            ],
        },
        {
            name: 'Алина',
            advantages: [
                'Работает в кофейне',
                'Мечтает о собственном бизнесе',
                'Активная и общительная',
            ],
            limitations: [
                'Иногда непостоянна в своих решениях',
            ],
        },
        {
            name: 'Аня',
            advantages: [
                'Программист',
                'Хочет изучать экономику и научиться управлять деньгами',
                'Рассудительная и целеустремленная',
            ],
            limitations: [
                'Тяжело заводит новые знакомства',
            ],
        },
        {
            name: 'Юля',
            advantages: [
                'Закончила ВУЗ',
                'Мечтает о собственном доме и успешной карьере',
                'Внимательная и трудолюбивая',
            ],
            limitations: [
                'Медлительна в принятии решений',
            ],
        },
    ],
};
