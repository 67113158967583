import PERSONS_IDS from '@/core/constants/persons';

const PERSONS = PERSONS_IDS;

export const IMAGES_NAMES = {
    MAIN: 'main',
    PAPERS: 'papers',
    HOUSE: 'house',
    ACC: 'acc',
    WORK: 'work',
    PYRAMID: 'pyramid',
    BANK: 'bank',
    BUS: 'bus',
    CAR: 'car',
    ANGRY: 'angry',
};


export const STEP_IMAGES = {
    [IMAGES_NAMES.MAIN]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.MAIN}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.MAIN}.png`),
    },
    [IMAGES_NAMES.PAPERS]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.PAPERS}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.PAPERS}.png`),
    },
    [IMAGES_NAMES.HOUSE]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.HOUSE}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.HOUSE}.png`),
    },
    [IMAGES_NAMES.ACC]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.ACC}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.ACC}.png`),
    },
    [IMAGES_NAMES.WORK]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.WORK}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.WORK}.png`),
    },
    [IMAGES_NAMES.PYRAMID]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.PYRAMID}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.PYRAMID}.png`),
    },
    [IMAGES_NAMES.BANK]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.BANK}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.BANK}.png`),
    },
    [IMAGES_NAMES.BUS]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.BUS}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.BUS}.png`),
    },
    [IMAGES_NAMES.CAR]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.CAR}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.CAR}.png`),
    },
    [IMAGES_NAMES.ANGRY]: {
        [PERSONS.BOY]: require(`@/assets/images/steps/${PERSONS.BOY}_${IMAGES_NAMES.ANGRY}.png`),
        [PERSONS.GIRL]: require(`@/assets/images/steps/${PERSONS.GIRL}_${IMAGES_NAMES.ANGRY}.png`),
    },
};
