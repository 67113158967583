import {personGender, personTypes} from '@/core/model/persons';

export default {
    namespaced: true,
    state: {
        persons: personGender,
        personTypes,
        selectedPerson: null,
        selectedPersonId: 'girl',
    },
    getters: {
        persons(state) {
            return state.persons;
        },
        personTypes(state) {
            return state.personTypes[state.selectedPersonId];
        },
        selectedPersonId(state) {
            return state.selectedPersonId;
        },
        selectedPerson(state) {
            // return state.persons.find((person) => person.id === state.selectedPersonId) || null;
            return state.selectedPerson;
        },
    },
    mutations: {
        updateSelectedPerson(state, id) {
            state.selectedPersonId = id;
            state.selectedPerson = state.persons.find((person) => person.id === state.selectedPersonId);
        },
        updateSelectedPersonType(state, personType) {
            state.selectedPerson = {...state.selectedPerson, ...personType};
        },
        resetPerson(state) {
            state.selectedPerson = null;
        },
    },
    actions: {
        selectPerson({commit, getters}, id) {
            return new Promise((resolve) => {
                commit('updateSelectedPerson', id);
                resolve(getters.selectedPerson);
            });
        },
        updatePersonType({commit, getters}, personType) {
            return new Promise((resolve) => {
                commit('updateSelectedPersonType', personType);
                resolve(getters.selectedPerson);
            });
        },
    },
};
