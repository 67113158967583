import {SERVICES, SCHEMAS, ACTIONS, FINAL_TYPES} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: 'ПРОЙДИТЕ КВИЗ',
        fund: 50000,
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Добро пожаловать в прошлое! Вы нашли машину времени и перенеслись на несколько лет назад в город Центр-сити. У Вас есть 50 000 Ц. Ваша цель – добиться успеха. Что будете делать дальше?',
        answers: [
            {
                text: 'Сначала просто изучу город и арендую квартиру. Мне нужно всё обдумать.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 2,
            },
            {
                text: 'Придумаю что-нибудь необычное. Например, я могу производить эко-сладости. Нужно купить всё необходимое. Может стать самозанятым?',
                action: ACTIONS.NEXT,
                stepFund: 45000,
                increase: false,
                nextStepIndex: 0,
                schema: SCHEMAS.SELF_EMPLOYED_SCHEMA,
            },
            {
                text: 'Можно попробовать открыть бизнес. Кофейня?',
                action: ACTIONS.NEXT,
                stepFund: 45000,
                increase: false,
                schema: SCHEMAS.CAFE_SCHEMA,
                nextStepIndex: 0,
            },
        ],
    },
    // Second chance step data
    {
        stepIndex: 1.2,
        title: 'Вам выпал второй шанс добиться успеха в Центр-сити!',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы решили снять квартиру и арендодатель предложил вложить 30 000 Ц в прибыльное дело: можно получать доход за счет взносов новых участников и увеличить свой капитал. Пришло время принять решение.',
        answers: [
            {
                text: 'Если не соглашусь, то упущу шанс увеличить капитал. Кто не рискует... собственно, вы знаете.',
                nextStepIndex: 5,
                action: ACTIONS.NEXT,
                stepFund: 30000,
                increase: false,
            },
            {
                text: 'Хм... Звучит заманчиво. Но лучше поищу работу. Куплю журнал с объявлениями.',
                nextStepIndex: 3,
                action: ACTIONS.NEXT,
                stepFund: 500,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 2,
        title: 'Вы нашли квартиру',
        image: STEP_IMAGES[IMAGES_NAMES.HOUSE],
        question: 'Вы нашли квартиру и арендодатель предложил вложить 30 000 Ц в прибыльное дело: вы сможете получать доход за счет взносов от новых участников. Можно увеличить свой капитал в 3 раза! Пришло время принять решение.',
        answers: [
            {
                text: 'Если не соглашусь, то упущу шанс увеличить капитал. Кто не рискует... собственно, вы знаете.',
                nextStepIndex: 5,
                action: ACTIONS.NEXT,
                stepFund: 30000,
                increase: false,
            },
            {
                text: 'Хм... Звучит заманчиво. Но лучше поищу работу. Куплю журнал с объявлениями.',
                nextStepIndex: 3,
                action: ACTIONS.NEXT,
                stepFund: 500,
                increase: false,
            },
            {
                text: 'Теперь я знаю, что у арендодателя есть деньги. Предложу открыть совместный бизнес! Может быть магазин сладостей?',
                action: ACTIONS.NETX,
                stepFund: 30000,
                increase: false,
                schema: SCHEMAS.SELF_EMPLOYED_SCHEMA,
                nextStepIndex: 1.2,

            },
        ],
    },
    {
        stepIndex: 3,
        title: 'Вы изучили объявления',
        image: STEP_IMAGES[IMAGES_NAMES.PAPERS],
        question: 'Вы читали объявления и увидели новость на первой полосе: «Центр-инвест» открывает Центр финансовой грамотности (ЦФГ). И вы решаете пройти бесплатное обучение. Теперь вы знаете все о финансах!',
        answers: [
            {
                text: 'Отлично! Теперь можно найти достойную работу в сфере финансов. Нужно купить приличный костюм.',
                nextStepIndex: 4,
                action: ACTIONS.NEXT,
                stepFund: 2000,
                increase: false,
            },
            {
                text: 'А может быть попробовать открыть свой бизнес? Например, магазин сладостей.',
                action: ACTIONS.FAIL,
                stepFund: 30000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы еще не достаточно обучились, чтобы открывать собственный бизнес. Для этого вам нужны дополнительные знания! Необдуманный риск привел вас к разорению.',
                },
            },
            {
                text: 'Курсы ЦФГ натолкнули на мысль стать финансовым аналитиком. Нужно купить новый дипломат для собеседования.',
                nextStepIndex: 4,
                action: ACTIONS.NEXT,
                stepFund: 1000,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 4,
        title: 'Вы нашли работу',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Вы устраиваетесь в известную компанию и занимаете должность помощника. А уже через год становитесь главным финансовым аналитиком. Курсы Центра финансовой грамотности помогли вам!',
        fund: 200000,
        answers: [
            {
                text: 'У меня стабильная работа и моего капитала достаточно для первоначального взноса. Возьму ипотеку!',
                action: ACTIONS.WIN,
                stepFund: 100000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'Работа финансовым аналитиком помогла вам понять, что инфляция может обесценить все сбережения. Собственная квартира и ипотека в «Центр-инвест» - отличное решение. Вы не только смогли стать владельцем новой недвижимости, но и получили повышение!',
                },
            },
            {
                text: 'У меня есть скромный капитал, куплю старую машину. Не буду оформлять кредит.',
                action: ACTIONS.FAIL,
                stepFund: 200000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.AUTO_CREDIT_ID,
                    message: 'Вы стали владельцем старого авто, но машина сломалась через месяц. Эх, а можно было выгодный автокредит взять.',
                },
            },
            {
                text: 'Недвижимость? Машина? Подумаем об этом позже. А пока буду копить деньги. Дома есть сейф.',
                action: ACTIONS.FAIL,
                stepFund: 200000,
                increase: true,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.DEPOSIT_ID,
                    message: 'Вы накопили достаточную сумму денег, но хранили их дома. Мошенники обокрали вас.',
                },
            },
        ],
    },
    {
        stepIndex: 5,
        title: 'Вы потеряли квартиру',
        image: STEP_IMAGES[IMAGES_NAMES.PYRAMID],
        question: 'Получить доход от привлечения новых участников? Вы наткнулись на финансовую пирамиду! Теперь у вашего арендодателя проблемы с законом, а вы остались без квартиры.',
        answers: [
            {
                text: 'На оставшиеся деньги найму хорошего юриста. Кто-то должен вернуть мои сбережения.',
                nextStepIndex: 8,
                action: ACTIONS.NEXT,
                stepFund: 9500,
                increase: false,
            },
            {
                text: 'Попробую просто отвлечься и почитать новости.',
                nextStepIndex: 7,
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: false,
            },
            {
                text: 'Экономика меня привлекает. Нужно узнать, что нового в сфере финансов.',
                nextStepIndex: 6,
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 6,
        title: 'Вы узнали о ЦФГ',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Новость о которой говорят все: «Центр-инвест» открывает Центр финансовой грамотности (ЦФГ). И вы решате пройти бесплатное обучение. Теперь вы знаете все о финансах!',
        answers: [
            {
                text: 'Может пора найти работу в сфере финансов? Нужно купить приличный костюм.',
                nextStepIndex: 4,
                action: ACTIONS.NEXT,
                stepFund: 2000,
                increase: false,
            },
            {
                text: 'Сложно принимать решения с таким капиталом. Нужны деньги. Может быть мне поможет лотерейный билет?',
                action: ACTIONS.FAIL,
                stepFund: 500,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.CFG_ID,
                    message: 'Увы, но удача не на вашей стороне. Видимо, вы пропускали занятия по финансовой грамотности, иначе, знали бы, что увеличить свой капитал можно своими знаниями.',
                },
            },
            {
                text: 'Курсы ЦФГ натолкнули на мысль стать финансовым аналитиком. Нужно купить новый дипломат для собеседования.',
                nextStepIndex: 4,
                action: ACTIONS.NEXT,
                stepFund: 1000,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 7,
        title: 'Вы узнали о ЦФГ',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Новость на первой полосе: «Центр-инвест» открывает Центр финансовой грамотности (ЦФГ). Это отличная возможность бесплатно получить знания. Вы прошли обучение и вам предложили стажировку в банке! Благодаря этому вы смогли увеличить свой капитал.',
        fund: 200000,
        answers: [
            {
                text: 'Пора подумать об ипотеке, ведь у меня есть стабильная работа и моего капитала достаточно для первоначального взноса.',
                action: ACTIONS.WIN,
                stepFund: 150000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'Работа в банке помогла вам понять, что инфляция может обесценить все сбережения. Собственная квартира и ипотека в «Центр-инвест» - отличное решение. Вы не только смогли стать владельцем новой недвижимости, но и получили повышение!',
                },
            },
            {
                text: 'Еще рано брать ипотеку. Внесу очередную арендную плату за съемную квартиру и буду копить деньги.',
                action: ACTIONS.FAIL,
                stepFund: 10000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.DEPOSIT_ID,
                    message: 'Вы накопили достаточную сумму денег, но хранили их на съемной квартире. Мошенники обокрали вас.',
                },
            },
            {
                text: 'Сниму квартиру подешевле, чтобы быстрее накопить сбережения.',
                action: ACTIONS.FAIL,
                stepFund: 5000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'В квартире не было ремонта уже много лет. Из-за старого крана вы затопили соседей и потратили оставшиеся сбережения на ремонт. Увы, вы проиграли. А может попробовать взять ипотеку?',
                },
            },
        ],
    },
    {
        stepIndex: 8,
        title: 'Вы потеряли деньги',
        image: STEP_IMAGES[IMAGES_NAMES.BUS],
        question: 'Вы наткнулись на мошенника, который украл последние деньги. Осталось только на проезд...',
        fund: 100,
        answers: [
            {
                text: 'Куплю лотерейный билет. Ну не может же мне всегда так не везти.',
                action: ACTIONS.FAIL,
                stepFund: 100,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.CFG_ID,
                    message: 'Финансовая пирамида и лотерейные билеты? Увеличить свой капитал можно своими знаниями!',
                },
            },
            {
                text: 'Нужно купить журнал и посмотреть вакансии. Мне нужна работа.',
                nextStepIndex: 10,
                action: ACTIONS.NEXT,
                stepFund: 100,
                increase: false,
            },
            {
                text: 'Потрачу последние деньги на кофе! Может идея сама меня найдет.',
                nextStepIndex: 9,
                action: ACTIONS.NEXT,
                stepFund: 100,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 9,
        title: 'Вы услышали про банк «Центр-инвест»',
        image: STEP_IMAGES[IMAGES_NAMES.BANK],
        question: 'В уютной кофейне вы услышали про банк «Центр-инвест». И у вас получилось устроиться на работу. Вы накопили 200 000 Ц. Пора подумать о недвижимости.',
        fund: 200000,
        answers: [
            {
                text: 'Еще рано брать ипотеку. Внесу очередную арендную плату за съемную квартиру и буду копить деньги.',
                action: ACTIONS.FAIL,
                stepFund: 10000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.DEPOSIT_ID,
                    message: 'Вы накопили достаточную сумму денег, но хранили их на съемной квартире. Мошенники обокрали вас.',
                },
            },
            {
                text: 'Пора подумать об ипотеке. У меня есть стабильная работа и моего капитала достаточно для первоначального взноса.',
                action: ACTIONS.WIN,
                stepFund: 150000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'Работа в банке помогла вам понять, что инфляция может обесценить все сбережения. Собственная квартира и ипотека в «Центр-инвест» - отличное решение. Вы не только смогли стать владельцем новой недвижимости, но и получили повышение!',
                },
            },
            {
                text: 'Сниму квартиру подешевле, чтобы быстрее накопить сбережения.',
                action: ACTIONS.FAIL,
                stepFund: 5000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'В квартире не было ремонта уже много лет. Из-за старого крана вы затопили соседей и потратили оставшиеся сбережения на ремонт. Увы, вы проиграли. А может попробовать взять ипотеку?',
                },
            },
        ],
    },
    {
        stepIndex: 10,
        title: 'Вас заинтересовал банк «Центр-инвест»',
        image: STEP_IMAGES[IMAGES_NAMES.BANK],
        question: 'Новость на первой полосе: В 2010 году банк «Центр-инвест» успешно реализовал программу «Юг России против глобального кризиса». Вас заинтересовал банк, и вы устраиваетесь туда на работу. Благодаря этому вы смогли увеличить свой капитал.',
        fund: 150000,
        answers: [
            {
                text: 'Оформлять ипотеку еще рано. Внесу очередную арендную плату за съемную квартиру и буду копить деньги.',
                action: ACTIONS.FAIL,
                stepFund: 10000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.DEPOSIT_ID,
                    message: 'Вы накопили достаточную сумму денег, но хранили их на съемной квартире. Мошенники обокрали вас.',
                },
            },
            {
                text: 'У меня есть работа и деньги на первоначальный взнос. Возьму ипотеку!',
                action: ACTIONS.WIN,
                stepFund: 150000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.MORTGAGE_ID,
                    message: 'Работа в банке помогла вам понять, что инфляция может обесценить все сбережения. Собственная квартира и ипотека в «Центр-инвест» - отличное решение. Вы не только смогли стать владельцем новой недвижимости, но и получили повышение!',
                },
            },
            {
                text: 'А может быть купить машину? Внему очередную плату за съемную квартиру и подумаю про авто.',
                nextStepIndex: 11,
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
            },
        ],
    },
    {
        stepIndex: 11,
        title: 'Вы решили купить машину',
        image: STEP_IMAGES[IMAGES_NAMES.CAR],
        question: 'Да, решено. Хочется добираться на работу с комфортом. Было принято решение копить на машину! Скромное начало, но уже есть первые накопления.',
        answers: [
            {
                text: 'Очень кстати - в моей квартире есть сейф. Еще немного накоплю и стану владельцем машины.',
                action: ACTIONS.FAIL,
                stepFund: 0,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.DEPOSIT_ID,
                    message: 'К сожалению, вы оказались не единственным арендатором, а значит, доступ к сейфу есть не только у вас. Все ваши деньги украли. Нельзя хранить деньги дома.',
                },
            },
            {
                text: 'Куплю авто на 100 000 Ц. Лучше, чем копить еще несколько лет.',
                action: ACTIONS.FAIL,
                stepFund: 100000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.AUTO_CREDIT_ID,
                    message: 'Вы стали владельцем старого авто и машина сломалась через месяц. Эх, а можно было выгодный автокредит взять.',
                },
            },
            {
                text: 'Стабильная работа есть, да и на первоначальный взнос хватает. Возьму автокредит.',
                action: ACTIONS.WIN,
                stepFund: 100000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.AUTO_CREDIT_ID,
                    message: 'Стоимость авто так быстро растет, что ваша машина только дорожает. Вы каждый день добирались на работу с комфортом и это помогло добиться успеха в работе. Поздравляем!',
                },
            },
        ],
    },
];
