const WIN_TYPE = 'win';
const FAIL_TYPE = 'fail';
const ONE_MORE_TYPE = 'one-more';

export default {
    WIN_TYPE,
    FAIL_TYPE,
    ONE_MORE_TYPE,
    [WIN_TYPE]: {
        class: 'win',
        image: '',
        title: 'Вы победили',
    },
    [FAIL_TYPE]: {
        class: 'fail',
        image: '',
        title: 'Вы проиграли',
    },
    [ONE_MORE_TYPE]: {
        class: 'one-more',
        image: '',
        title: 'Второй шанс',
    },
};
