import {ACTIONS, SERVICES, FINAL_TYPES} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.BANK],
        question: 'Это было отличным решением. Вам удалось досрочно закрыть кредит, закупить товар, и у вас остались деньги. Но ситуация на рынке изменилась, а ваших знаний недостаточно.',
        fund: 55000,
        answers: [
            {
                text: 'В Интернете очень много информации. Обучусь самостоятельно.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1,
            },
            {
                text: 'Куплю курс про развитие бизнеса в Instagram.',
                action: ACTIONS.NEXT,
                stepFund: 20000,
                increase: false,
                nextStepIndex: 2,
            },
            {
                text: 'Бесплатный акселератор для бизнеса от "Центр-инвест". Разве бесплатные знания могут быть полезны?',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 3,
            },
        ],
    },
    {
        stepIndex: 1,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Информации оказалось слишком много. Вы потеряли не только драгоценное время, но и прибыль, потому что не уделяли должного внимания бизнесу. Нужно спасать ситуацию.',
        answers: [
            {
                text: 'Мне поможет личный ассистент. Надеюсь, дела пойдут в гору, и я смогу заплатить ему.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Нужно проанализировать конкурентов и разработать бизнес-план.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы очень старались, но вам не хватило знаний. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'В одной статье было написано, что можно купить компромат на своих конкурентов и разорить их!',
                action: ACTIONS.FAIL,
                stepFund: 10000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Увы, вы проиграли. Конкуренты подали на вас в суд и бизнес закрыли. В Центр-сити дела так не ведут.',
                },
            },
        ],
    },
    {
        stepIndex: 2,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Информации оказалось слишком много. Вы потеряли не только драгоценное время, но и прибыль, потому что не уделяли должного внимания бизнесу. Нужно спасать ситуацию.',
        answers: [
            {
                text: 'Мне поможет личный ассистент. Надеюсь, дела пойдут в гору, и я смогу заплатить ему за спасение.',
                action: ACTIONS.ONE_MORE,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы наняли ассистента, который тоже не разбирается в бизнесе. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Нужно проанализировать конкурентов и самостоятельно разработать бизнес-план.',
                action: ACTIONS.ONE_MORE,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы очень старались, но вам не хватило знаний. Время упущено, вам пришлось продать бизнес, ведь накопились долги. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'В одной статье было написано, что можно купить компромат на своих конкурентов и разорить их!',
                action: ACTIONS.FAIL,
                stepFund: 15000,
                increase: false,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Увы, вы проиграли. Конкуренты подали на вас в суд и бизнес закрыли. В Центр-сити дела так не ведут.',
                },
            },
        ],
    },
    {
        stepIndex: 3,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Акселератор от "Центр-инвест" помог вам разобраться в продажах и аналитике. Вы решили взять кредит в банке "Центр-инвест". Что дальше?',
        answers: [
            {
                text: 'Нужно расширить ассортимент!',
                action: ACTIONS.NEXT,
                stepFund: 20000,
                increase: false,
                nextStepIndex: 4,
            },
            {
                text: 'Теперь я умею анализировать рынок. Буду производить новые эко-сладости!',
                action: ACTIONS.NEXT,
                stepFund: 20000,
                increase: false,
                nextStepIndex: 7,
            },
            {
                text: 'Найму маркетолога, который сделает рекламу.',
                action: ACTIONS.NEXT,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 8,
            },
        ],
    },
    {
        stepIndex: 4,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы расширили ассортимент и смогли закрыть кредит! А еще разработали 3 варианта развития бизнеса. Что дальше?',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 5,
            },
            {
                text: 'Можно открыть интернет-магазин с эко-сладостями!',
                action: ACTIONS.RANDOM,
                stepFund: 25000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и удвоить ваш капитал! ',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 8,
            },
        ],
    },
    {
        stepIndex: 5,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Идея оказалась действительно хорошей и ваши продажи выросли! Что дальше?',
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 15000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать . Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Не буду рисковать. Продолжу работу в том же темпе.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 6,
            },
            {
                text: 'Пора переходить на Интернет-торговлю. Мне нужен сайт.',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 6,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Ваши конкуренты придумали новое бизнес-решение и обошли вас. Вы теряете прибыль. Нужно действовать - вернёмся назад!',
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 15000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать . Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Не буду рисковать. Продолжу работу в том же темпе.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 6,
                blocked: true,
            },
            {
                text: 'Пора переходить на Интернет-торговлю. Мне нужен сайт.',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 7,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Новый эко-товар оказался востребованным и вы заняли лидирующие позиции на рынке.',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 5,
            },
            {
                text: 'Можно открыть интернет-магазин с эко-сладостями!',
                action: ACTIONS.RANDOM,
                stepFund: 25000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и удвоить ваш капитал! ',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 15000,
                increase: false,
                nextStepIndex: 8,
            },
        ],
    },
    {
        stepIndex: 8,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы наняли маркетолога, но его рекламная кампания не удалась. Нужно другое решение.',
        answers: [
            {
                text: 'Решено: интернет-торговля.',
                action: ACTIONS.RANDOM,
                stepFund: 15000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться Интернет-торговля, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Идея отличная, но конкуренты подставили вас. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму более опытного маркетолога!',
                action: ACTIONS.WIN,
                stepFund: 15000,
                increase: false,
                final: {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Победа! Благодаря успешной рекламной кампании вам удалось занять лидирующую позицию на рынке и увеличить капитал. Настойчивость привела вас к успеху!',
                },
            },
        ],
    },
];
