import {ACTIONS, SERVICES, FINAL_TYPES} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Акселератор от "Центр-инвест" помог вам разобраться в продажах и аналитике. Вы решили взять кредит в банке "Центр-инвест". Что дальше?',
        answers: [
            {
                text: 'Нужно расширить ассортимент! ',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1,
            },
            {
                text: 'Теперь я умею анализировать потребности клиентов. Буду к кофе предлагать вкусные пончики!',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 2,
            },
            {
                text: 'Найму маркетолога, который сделает рекламу.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 1,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы расширили ассортимент и разработали 3 варианта развития бизнеса. Что дальше?',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 3,
            },
            {
                text: 'А может сделать доставку пончиков?',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт для заказов оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться доставка еды, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 2,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Новыe пончики оказались востребованным и вы заняли лидирующие позиции на рынке.',
        answers: [
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 3,
            },
            {
                text: 'А может сделать доставку пончиков?',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт для заказов оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться доставка еды, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 3,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.WORK],
        question: 'Идея оказалась действительно хорошей и ваши продажи выросли! Что дальше?',
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 5000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Не буду рисковать. Продолжу работу в том же темпе.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 4,
            },
            {
                text: 'А может всё же сделать доставку пончиков?',
                action: ACTIONS.RANDOM,
                stepFund: 10000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт для заказов оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться доставка еды, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 4,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ANGRY],
        question: 'Ваши конкуренты придумали новое бизнес-решение и обошли вас. Вы теряете прибыль. Нужно действовать - вернёмся назад!',
        answers: [
            {
                text: 'Нужно запустить рекламную кампанию.',
                action: ACTIONS.RANDOM,
                stepFund: 5000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Вы отличный маркетолог, но конкуренты серьезно подставили вас. Бизнес пришлось продать. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! Рекламная кампания оказалось настолько успешной, что вам удалось увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Не буду рисковать. Продолжу работу в том же темпе.',
                blocked: true,
            },
            {
                text: 'А может всё же сделать доставку пончиков?',
                action: ACTIONS.RANDOM,
                stepFund: 10000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт для заказов оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться доставка еды, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
        ],
    },
    {
        stepIndex: 5,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Вы наняли маркетолога, но его рекламная кампания не удалась. Нужно другое решение.',
        answers: [
            {
                text: 'Решено: доставка пончиков.',
                action: ACTIONS.RANDOM,
                stepFund: 20000,
                increase: false,
                final: [
                    {
                        type: FINAL_TYPES.ONE_MORE_TYPE,
                        fund: 40000,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Программист, который делал сайт для заказов оказался мошенником и украл деньги. Даже если вы действуете правильно, к сожалению, такое случается. Бизнес пришлось продать, чтобы оплатить долги. Но позже вы узнали о наследстве. Вот это новость!',
                    },
                    {
                        type: FINAL_TYPES.WIN_TYPE,
                        service: SERVICES.ACC_PROGRAM_ID,
                        message: 'Победа! В Центр-сити только начинает развиваться доставка еды, поэтому вам удалось занять лидирующую позицию на рынке и увеличить капитал!',
                    },
                ],
            },
            {
                text: 'Сделаю акцию, чтобы привлечь внимание клиентов.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Идея отличная, но конкуренты подставили вас. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму более опытного маркетолога!',
                action: ACTIONS.WIN,
                stepFund: 5000,
                increase: false,
                final: 
                {
                    type: FINAL_TYPES.WIN_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Победа! Благодаря успешной рекламной кампании вам удалось занять лидирующую позицию на рынке и увеличить капитал. Настойчивость привела вас к успеху!',
                },
            },
        ],
    },
];
