import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        redirect: {
            name: 'PersonGender',
        },
        children: [{
            path: '',
            name: 'PersonGender',
            component: () => import('@/views/Persons/PersonGenderView.vue'),
        },
        {
            path: '/type',
            name: 'PersonType',
            component: () => import('@/views/Persons/PersonTypeView.vue'),
        }],
    },
    {
        path: '/quiz',
        name: 'Quiz',
        component: () => import('@/views/Quiz.vue'),
    },
    {
        path: '/quiz-fail',
        name: 'Fail',
        component: () => import('@/views/Fail.vue'),
    },
    {
        path: '/quiz-win',
        name: 'Win',
        component: () => import('@/views/Win.vue'),
    },
    {
        path: '/second-chance',
        name: 'SecondChance',
        component: () => import('@/views/SecondChance.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
