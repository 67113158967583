import {ACTIONS, SERVICES, FINAL_TYPES} from '@/core/constants';
import {STEP_IMAGES, IMAGES_NAMES} from '@/core/model/step-images';

export default [
    {
        stepIndex: 0,
        title: '',
        fund: 15000,
        image: STEP_IMAGES[IMAGES_NAMES.BANK],
        question: 'Центр-инвест предложил выгодные условия, вам удалось сделать ремонт и вернуть кредит. Пора развивать бизнес.',
        answers: [
            {
                text: 'Найду компромат на своих конкурентов, чтобы разорить их.',
                action: ACTIONS.ONE_MORE,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'В Центр-сити дела так не ведут. Конкуренты подали на вас в суд, и бизнес пришлось продать, чтобы оплатить расходы. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Мне нужно придумать креативное решение',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 1,
            },
            {
                text: 'Найму маркетолога, который сделает рекламу.',
                action: ACTIONS.NEXT,
                stepFund: 5000,
                increase: false,
                nextStepIndex: 5,
            },
        ],
    },
    {
        stepIndex: 1,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.ACC],
        question: 'Вы прошли бесплатное обучение в Акселераторе от банка "Центр-инвест", поэтому знаете, что нужно проанализировать потребности клиентов. Бизнес-центр, в котором расположена кофейня, работает с 8.00. Принято решение работать с 7.30. А еще можно добавить в меню бизнес-ланч!',
        answers: [
            {
                text: 'Сейчас нет денег платить за переработки. Утром буду работать самостоятельно. Нельзя тратить весь капитал сразу.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 2,
            },
            {
                text: 'Оставшихся денег достаточно, чтобы оплатить переработки первое время.',
                action: ACTIONS.NEXT,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 3,
            },
            {
                text: 'Наверное, нужно отказаться от этой идеи и подумать ещё.',
                action: ACTIONS.NEXT,
                stepFund: 0,
                increase: true,
                nextStepIndex: 4,
            },
        ],
    },
    {
        stepIndex: 2,
        title: '',
        fund: 50000,
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Идея отличная! Вам удалось привлечь клиентов и заработать. Но нагрянула проверка и у вас отсутствует одна из лицензий. Что будете делать?',
        answers: [
            {
                text: 'Найму самого дорогого юриста.',
                action: ACTIONS.ONE_MORE,
                stepFund: 30000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался дилетантом. Вы зря потратили деньги, бизнес в итоге закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму юриста-новичка.',
                action: ACTIONS.ONE_MORE,
                stepFund: 5000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался слишком неопытным, вы зря потратили деньги. Ваш бизнес закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 3,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Пока вы размышляли, нагрянула проверка и оказалось, что нет одной из необходимых лицензий. Что делать?',
        answers: [
            {
                text: 'Найму юриста-новичка.',
                action: ACTIONS.ONE_MORE,
                stepFund: 5000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался слишком неопытным, вы зря потратили деньги. Ваш бизнес закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму самого дорогого юриста.',
                stepFund: 10000,
                increase: false,
                blocked: true,
            },
        ],
    },
    {
        stepIndex: 4,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Пока вы размышляли, нагрянула проверка и оказалось, что нет одной из необходимых лицензий. Что делать?',
        answers: [
            {
                text: 'Найму самого дорогого юриста.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался дилетантом. Вы зря потратили деньги, бизнес в итоге закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму юриста-новичка.',
                action: ACTIONS.ONE_MORE,
                stepFund: 5000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался слишком неопытным, вы зря потратили деньги. Ваш бизнес закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
    {
        stepIndex: 5,
        title: '',
        image: STEP_IMAGES[IMAGES_NAMES.MAIN],
        question: 'Реклама оказалось успешной. Вам удалось привлечь клиентов и немного заработать. Но нагрянула проверка и у вас отсутствует одна из лицензий. Что будете делать?',
        answers: [
            {
                text: 'Не буду тратить деньги на юриста, а "подарю" их проверяющим.',
                action: ACTIONS.FAIL,
                stepFund: 10000,
                increase: false,
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.FAIL_TYPE,
                    service: SERVICES.ACC_PROGRAM_ID,
                    message: 'Вы проиграли. Центр-сити за честный бизнес!',
                },
            },
            {
                text: 'Найму самого дорогого юриста.',
                action: ACTIONS.ONE_MORE,
                stepFund: 10000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался дилетантом. Вы зря потратили деньги, бизнес в итоге закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
            {
                text: 'Найму юриста-новичка.',
                action: ACTIONS.ONE_MORE,
                stepFund: 5000,
                increase: false,                
                nextStepIndex: 1.2,
                final: {
                    type: FINAL_TYPES.ONE_MORE_TYPE,
                    fund: 40000,
                    service: SERVICES.BUSINESS_CREDIT_ID,
                    message: 'Увы, но юрист оказался слишком неопытным, вы зря потратили деньги. Ваш бизнес закрыли. Даже если вы действуете правильно, к сожалению, такое случается. Но позже вы узнали о наследстве. Вот это новость!',
                },
            },
        ],
    },
];
