import {SERVICES} from '@/core/constants';

export default {
    [SERVICES.ACC_PROGRAM_ID]: {
        title: 'АКСЕЛЕРАТОР БАНКА «ЦЕНТР-ИНВЕСТ»',
        link: 'https://acc.centrinvest.ru/',
        linkTitle: 'Узнать больше',
        description: 'Прокачай свои навыки и пройди бесплатное обучение. Сделай свой бизнес успешнее вместе с опытной командой.',
    },
    [SERVICES.MORTGAGE_ID]: {
        title: 'Ипотечное кредитование БАНКА «ЦЕНТР-ИНВЕСТ»',
        link: 'https://www.centrinvest.ru/ru/fiz/kreditovanie/ipoteka/',
        linkTitle: 'Узнать больше',
        description: 'Банк «Центр-инвест» поможет осуществить мечту о собственном жилье. Ипотечное кредитование без страхования и комиссий.',
    },
    [SERVICES.CREDIT_ID]: {
        title: 'Потребительский кредит БАНКА «ЦЕНТР-ИНВЕСТ»',
        link: 'https://www.centrinvest.ru/ru/fiz/kreditovanie/potrebitelskij-kredit/',
        linkTitle: 'Узнать больше',
        description: 'Нужны деньги на ремонт, покупку бытовой техники или мебели, ремонт дома, оплату обучения, отдых или вы желаете рефинансировать на более выгодных условиях потребительский кредит, оформленный в стороннем банке? В банке «Центр-инвест» Вы можете получить потребительский кредит на любые цели.',
    },
    [SERVICES.AUTO_CREDIT_ID]: {
        title: 'Автокредитование БАНКА «ЦЕНТР-ИНВЕСТ»',
        link: 'https://www.centrinvest.ru/ru/fiz/kreditovanie/avtokreditovanie/',
        linkTitle: 'Узнать больше',
        description: 'Мечтаете о собственном авто? Мечты становятся реальностью с банком «Центр-инвест».',
    },
    [SERVICES.DEPOSIT_ID]: {
        title: 'Вклады БАНКА «ЦЕНТР-ИНВЕСТ»',
        link: 'https://www.centrinvest.ru/ru/fiz/vklady/',
        linkTitle: 'Узнать больше',
        description: 'Сохраняйте и приумножайте свои сбережения с рублевыми и валютными вкладами от «Центр-инвест». Ваши сбережения в безопасности!',
    },

    [SERVICES.CFG_ID]: {
        title: 'Центр финансовой грамотности',
        link: 'https://xn----7sbbzgbvlb6b9j.xn--p1ai/',
        linkTitle: 'Узнать больше',
        description: 'Пройди обучение в «Центре финансовой грамотности» под руководством ведущих специалистов банка. Лучшие студенты приглашаются на работу в банк «Центр-инвест». Это отличная возможность построить свою карьеру в крупнейшем южно-российском банке. Стань частью нашей команды!',
    },

    [SERVICES.BUSINESS_CREDIT_ID]: {
        title: 'КРЕДИТОВАНИЕ БИЗНЕСА',
        link: 'https://www.centrinvest.ru/ru/biz/programmy-kreditovanija/',
        linkTitle: 'Узнать больше',
        description: 'Выгодные кредиты для вашего бизнеса от банка "Центр-инвест"!',
    },    
};
