<template>
    <div id="app">
        <!-- <Testing /> -->
        <div class="wrapper">
            <Header />
            <transition name="fade" mode="out-in">
                <router-view/>
            </transition>
            <Footer />
        </div>
    </div>
</template>

<script>
import Header from '@/components/page-structure/Header';
import Footer from '@/components/page-structure/Footer';
// import Testing from '@/views/Testing';

export default {
    components: {
        Header,
        Footer,
        // Testing,
    },
    created() {
        this.$router.replace({name: 'Home'});
    },
};
</script>
