import * as schemas from '@/core/model';
import services from '@/core/model/services';
import {FINAL_TYPES, ACTIONS, SCHEMAS} from '@/core/constants';
import cloneDeep from 'lodash.clonedeep';

export default {
    namespaced: true,
    state: {
        currentStepIndex: 0,
        model: cloneDeep(schemas.mainSchema),
        stepFund: 50000,
        finalData: null,
        hasSecondChance: false,
    },
    getters: {
        quizModel(state) {
            return state.model;
        },
        quizStepData(state, getters) {
            return getters.quizModel.find((item) => item.stepIndex === state.currentStepIndex);
        },
        stepFund(state) {
            return state.stepFund;
        },
        finalData(state) {
            return {
                ...state.finalData,
                ...FINAL_TYPES[state.finalData.type],
                serviceData: services[state.finalData.service],
            };
        },
        hasSecondChance(state) {
            return state.hasSecondChance;
        },
    },
    mutations: {
        updateStep(state, step) {
            state.currentStepIndex = step;
        },
        updateStepFund(state, {value, increase, change}) {
            if (!change && typeof change !== 'undefined') {
                if (value !== 0) {
                    if (increase) {
                        state.stepFund = state.stepFund + value;
                    } else {
                        state.stepFund = state.stepFund - value;
                    }
                }
            } else {
                state.stepFund = value;
            }
        },
        updateSchema(state, schema) {
            state.model = cloneDeep(schemas[schema]);
        },
        updateFinal(state, data) {
            state.finalData = data.final;
        },
        resetQuizData(state) {
            state.model = cloneDeep(schemas.mainSchema);
            state.currentStepIndex = 0;
            state.finalData = null;
        },
        updateHasSecondChance(state) {
            state.hasSecondChance = true;
        },
    },
    actions: {
        changeStep({commit}, step) {
            return new Promise((resolve) => {
                commit('updateStep', step);
                resolve(step);
            });
        },
        changeStepFund({commit, getters}, {value, increase, change = false}) {
            return new Promise((resolve) => {
                const stepFund = getters.quizStepData;

                if (typeof stepFund.fund === 'number') {
                    commit('updateStepFund', {value: stepFund.fund});
                } else {
                    commit('updateStepFund', {value, increase, change});
                }

                resolve(stepFund);
            });
        },
        changeSchema({commit}, schema) {
            return new Promise((resolve) => {
                commit('updateSchema', schema);
                resolve(schema);
            });
        },
        setFinal({commit, getters, dispatch}, data) {
            return new Promise((resolve) => {
                commit('updateFinal', data);
                if (data.action === ACTIONS.ONE_MORE) {
                    dispatch('changeSchema', SCHEMAS.MAIN_SCHEMA);
                    commit('updateHasSecondChance');
                }
                resolve(getters.finalData);
            });
        },
        resetQuizData({commit}) {
            return new Promise((resolve) => {
                commit('resetQuizData');
                commit('persons/resetPerson', null, {root: true});
                resolve(true);
            });
        },
    },
};
